<template>
  <div class="e-list">
    <van-nav-bar left-text="返回"
                 left-arrow
                 @click-left="$router.go(-1)" />
    <van-grid :column-num="2"
              :gutter="16">
      <van-grid-item @click="
          $router.push({
            path: item.url,
            query: { ...item.query },
          })
        "
                     v-for="(item, index) in list"
                     :key="index">
        <div class="controls-list-item">
          <div>
            <div>{{ item.title }}</div>
          </div>
          <div>
            <div>
              <van-image width="55px"
                         height="55px"
                         fit="contain"
                         :src="item.img" />
            </div>
            <div>
              <span>{{ item.onNumber }}</span>
              <span>/{{ item.countNumber }}</span>
            </div>
          </div>
        </div>
      </van-grid-item>
    </van-grid>
  </div>
</template>
<script>
import {
  Image as VanImage,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  NavBar,
} from "vant";

import { getTerminalOnStatistics } from "@/api/TerminalEquipment/HomeApi.js";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [NavBar.name]: NavBar,
  },
  data () {
    return {
      list: [
        {
          type: 1,
          onNumber: 0,
          countNumber: 0,
          img: require("@/assets/灯泡.svg"),
          title: "智能照明灯",
          url: "/SmartClassroom/Filter",
          query: {
            equipment: 0,
          },
        },
        {
          type: 2,
          onNumber: 0,
          countNumber: 0,
          img: require("@/assets/空调.svg"),
          title: "空调",
          number: "920/11278",
          url: "/SmartClassroom/Filter",
          query: {
            equipment: 1,
          },
        },
        {
          type: 3,
          onNumber: 0,
          countNumber: 0,
          img: require("@/assets/窗帘.svg"),
          title: "窗帘",
          number: "917/11278",
          url: "/SmartClassroom/Filter",
          query: {
            equipment: 2,
          },
        },
        {
          type: 4,
          onNumber: 0,
          countNumber: 0,
          img: require("@/assets/自动门.svg"),
          title: "自动门",
          number: "214/12278",
          url: "/SmartClassroom/Filter",
          query: {
            equipment: 3,
          },
        },
        {
          type: 5,
          onNumber: 0,
          countNumber: 0,
          img: require("@/assets/室内检测仪器.svg"),
          title: "室内检测仪",
          number: "512/12278",
          url: "/SmartClassroom/Filter",
          query: {
            equipment: 4,
          },
        },
      ],
    };
  },
  methods: {
    async TerminalOnStatistics () {
      let res = await getTerminalOnStatistics();
      this.list.forEach((q) => {
        let info = this.getFirst(res.Data, q.type);
        if (info != null) {
          q.onNumber = info.OnNum;
          q.countNumber = info.CountNum;
        }
      });
    },
    //获取单个实体
    getFirst (list, terminaType) {
      let info = null;
      list.forEach((a) => {
        if (a.TerminalType == terminaType) {
          info = a;
        }
      });
      return info;
    },
  },
  computed: {},
  mounted () {
    this.TerminalOnStatistics();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";

.e-list {
  .controls-list-item {
    > div:first-child {
      color: $titleColor;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      width: 134px;
    }
    > div:last-child {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 13px;
      > div:last-child {
        margin-bottom: 8px;
        > span:first-child {
          color: $themeColor;
          font-size: 18px;
          font-weight: 500;
        }
        > span:last-child {
          display: inline-block;
          font-size: 12px;
          transform: scale(0.95);
          color: $titleColor;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "../../../style/public.scss";
.e-list {
  .van-nav-bar {
    background-color: transparent;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 13px;
    .van-icon {
      color: $titleColor;
      font-size: 22px;
      font-weight: 400;
    }
    .van-nav-bar__text {
      color: $titleColor;
      font-weight: 400;
      font-size: 22px;
    }
  }
}
</style>